import React, {Fragment, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {resolveRoute, useHistory, prepareSearchQueryParams} from '@computerrock/formation-router';
import {Panel, useStyles, ToggleTab, ToggleTabSwitch, SearchBox, ContentBlock} from '@ace-de/ui-components';
import {findCaseIcon, newCaseIcon, earthViewIcon, questionmarkIcon, waitingIcon, editDocumentIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import {alfClientTypes, alfInvoiceStatusTypes, alfInvoiceTypes, etmTaskStatusTypes, alfInvoiceSubStatusTypes, persistenceStates} from '@ace-de/eua-entity-types';
import MemberSearchBox from './ui-elements/MemberSearchBox';
import MyTasksPanel from './ui-elements/MyTasksPanel';
import ReporterTile from './ui-elements/ReporterTile';
import dashboardSearchTabs from './dashboardSearchTabs';
import routePaths from '../routePaths';
import config from '../config';
import leaAccessControl from '../leaAccessControl';
import {leaFeatureActions, leaFeatures} from '../application/leaFeatures';

const reporterTypes = {
    MANUAL_CHECK: 'MANUAL_CHECK',
    OVERDUE_TASKS: 'OVERDUE_TASKS',
    INVOICES_IN_CREATION: 'INVOICES_IN_CREATION',
    OPEN_ESTIMATIONS: 'OPEN_ESTIMATIONS',
    WACE_INVOICES: 'WACE_INVOICES',
    ELLA_INQUIRY: 'ELLA_INQUIRY',
};

const DashboardScreen = props => {
    const {cx} = useStyles();
    const history = useHistory();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('dashboard_screen');
    const {isFeatureListSet, reporterTasksAmount, invoicesEKR, invoicesWithOpenEstimations} = props;
    const {invoicesInStatusManualCheck, invoicesInStatusInCreation, invoicesWithSubStatusEllaInquiry} = props;
    const {invoicesPersistenceState, tasksPersistenceState} = props;
    const [selectedTab, setSelectedTab] = useState(dashboardSearchTabs.MEMBER);
    const isInvoicesPersistenceStatePending = invoicesPersistenceState === persistenceStates.PENDING;

    const reporterTileConfiguration = {
        [reporterTypes.MANUAL_CHECK]: {
            color: 'primary-light-c',
            icon: findCaseIcon,
            name: translateScreen('reporter_tile_title.manual_check'),
            amount: invoicesInStatusManualCheck || null,
            isPersistenceStatePending: isInvoicesPersistenceStatePending,
            redirectionPath: resolveRoute(
                routePaths.INVOICES_SEARCH,
                {},
                {search: prepareSearchQueryParams({
                    statuses: [alfInvoiceStatusTypes.MANUAL_CHECK],
                    excludedSubStatuses: [alfInvoiceSubStatusTypes.ELLA_INQUIRY],
                })},
            ),
        },
        [reporterTypes.OVERDUE_TASKS]: {
            color: 'additional-h',
            icon: editDocumentIcon,
            name: translateScreen('reporter_tile_title.overdue_tasks'),
            amount: reporterTasksAmount || null,
            isPersistenceStatePending: tasksPersistenceState === persistenceStates.PENDING,
            redirectionPath: resolveRoute(
                routePaths.TASKS_SEARCH,
                {},
                {search: prepareSearchQueryParams({status: etmTaskStatusTypes.OVERDUE})},
            ),
        },
        [reporterTypes.INVOICES_IN_CREATION]: {
            color: 'secondary-light-d',
            icon: newCaseIcon,
            name: translateScreen('reporter_tile_title.invoices_in_creation'),
            amount: invoicesInStatusInCreation || null,
            isPersistenceStatePending: isInvoicesPersistenceStatePending,
            redirectionPath: resolveRoute(
                routePaths.INVOICES_SEARCH,
                {},
                {search: prepareSearchQueryParams({statuses: [alfInvoiceStatusTypes.IN_CREATION]})},
            ),
        },
        [reporterTypes.OPEN_ESTIMATIONS]: {
            color: 'positive-light',
            icon: waitingIcon,
            name: translateScreen('reporter_tile_title.open_estimations'),
            amount: invoicesWithOpenEstimations || null,
            isPersistenceStatePending: isInvoicesPersistenceStatePending,
            redirectionPath: resolveRoute(
                routePaths.INVOICES_SEARCH,
                {},
                {search: prepareSearchQueryParams({
                    hasRelatedInvoice: 'false',
                    types: [alfInvoiceTypes.BBZ],
                    statuses: [alfInvoiceStatusTypes.APPROVED],
                })},
            ),
        },
        [reporterTypes.WACE_INVOICES]: {
            color: 'additional-i',
            icon: earthViewIcon,
            name: translateScreen('reporter_tile_title.wace_invoices'),
            amount: invoicesEKR || null,
            isPersistenceStatePending: isInvoicesPersistenceStatePending,
            redirectionPath: resolveRoute(
                routePaths.INVOICES_SEARCH,
                {},
                {search: prepareSearchQueryParams({
                    clients: alfClientTypes.WACE,
                    types: [alfInvoiceTypes.EKR],
                    hasEKRConnectedVKR: 'false',
                    statuses: Object.values(alfInvoiceStatusTypes).filter(status => {
                        return status === alfInvoiceStatusTypes.BOOKED
                            || status === alfInvoiceStatusTypes.APPROVED
                            || status === alfInvoiceStatusTypes.PAID;
                    }).join(','),
                })},
            ),
        },
        [reporterTypes.ELLA_INQUIRY]: {
            color: 'negative-light',
            icon: questionmarkIcon,
            name: translateScreen('reporter_tile_title.ella_inquiry'),
            amount: invoicesWithSubStatusEllaInquiry || null,
            isPersistenceStatePending: isInvoicesPersistenceStatePending,
            redirectionPath: resolveRoute(
                routePaths.INVOICES_SEARCH,
                {},
                {search: prepareSearchQueryParams({
                    clients: alfClientTypes.ACE,
                    subStatuses: alfInvoiceSubStatusTypes.ELLA_INQUIRY,
                })},
            ),
        },
    };
    const areTasksAvailable = leaAccessControl.grantFeatureAccess(leaFeatures.TASKS, leaFeatureActions.READ);
    const areKPIsAvailable = leaAccessControl.grantFeatureAccess(leaFeatures.KPI_INFO, leaFeatureActions.READ);

    const handleOnTabSwitch = value => {
        setSelectedTab(value);
    };
    if (!isFeatureListSet) return null;

    const openSearchResultsScreen = value => {
        if (value && value.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            const searchQueryParams = new URLSearchParams();
            searchQueryParams.append('searchTerm', value);
            history.replace(resolveRoute(
                routePaths.SERVICE_CASES_SEARCH,
                {},
                {search: searchQueryParams.toString()},
            ));
        }
    };

    return (
        <Fragment>
            <Panel
                title={translateScreen('search_box_panel_title.search')}
                contentClassName={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                ])}
            >
                <ToggleTabSwitch
                    name="memberCaseSearch"
                    className={cx('global!ace-u-margin--bottom-24')}
                    onChange={handleOnTabSwitch}
                    value={selectedTab}
                >
                    {Object.values(dashboardSearchTabs).map(tab => (
                        <ToggleTab
                            key={tab}
                            name={`${tab.toLowerCase()}Tab`}
                            value={tab}
                        >
                            {translate(`global.search_type.${tab.toLowerCase()}`).toUpperCase()}
                        </ToggleTab>
                    ))}
                </ToggleTabSwitch>
                {selectedTab === dashboardSearchTabs.MEMBER && (
                    <MemberSearchBox />
                )}
                {selectedTab === dashboardSearchTabs.CASE && (
                    <SearchBox
                        className={cx([
                            'global!ace-u-margin--top-24',
                            'global!ace-u-width--full',
                        ])}
                        isDropDownDisabled={true}
                        placeholder={translateScreen('search_box_placeholder.case_search')}
                        onSearchSubmit={openSearchResultsScreen}
                    />
                )}
            </Panel>
            {areKPIsAvailable && (
                <ContentBlock>
                    <div className={cx('global!ace-u-grid', 'global!ace-u-full-width')}>
                        {Object.entries(reporterTileConfiguration).map(([key, value]) => (
                            <ReporterTile
                                key={key}
                                color={value.color}
                                icon={value.icon}
                                name={value.name}
                                amount={value.amount}
                                redirectionPath={value.redirectionPath}
                                isPersistenceStatePending={value.isPersistenceStatePending}
                                history={history}
                            />
                        ))}
                    </div>
                </ContentBlock>
            )}
            {areTasksAvailable && (
                <MyTasksPanel />
            )}
        </Fragment>
    );
};

DashboardScreen.propTypes = {
    isFeatureListSet: PropTypes.bool.isRequired,
    reporterTasksAmount: PropTypes.number,
    invoicesEKR: PropTypes.number,
    invoicesInStatusManualCheck: PropTypes.number,
    invoicesInStatusInCreation: PropTypes.number,
    invoicesWithSubStatusEllaInquiry: PropTypes.number,
    invoicesWithOpenEstimations: PropTypes.number,
    invoicesPersistenceState: PropTypes.string.isRequired,
    tasksPersistenceState: PropTypes.string.isRequired,
};

DashboardScreen.defaultProps = {
    reporterTasksAmount: 0,
    invoicesEKR: 0,
    invoicesInStatusManualCheck: 0,
    invoicesInStatusInCreation: 0,
    invoicesWithSubStatusEllaInquiry: 0,
    invoicesWithOpenEstimations: 0,
};

const mapStateToProps = state => {
    return {
        isFeatureListSet: state.leaSystemAccessReducer.isFeatureListSet,
        reporterTasksAmount: state.dashboard.reporterTasksAmount,
        invoicesEKR: state.dashboard.invoicesEKR,
        invoicesInStatusManualCheck: state.dashboard.invoicesInStatusManualCheck,
        invoicesInStatusInCreation: state.dashboard.invoicesInStatusInCreation,
        invoicesWithSubStatusEllaInquiry: state.dashboard.invoicesWithSubStatusEllaInquiry,
        invoicesWithOpenEstimations: state.dashboard.invoicesWithOpenEstimations,
        invoicesPersistenceState: state.dashboard.invoicesPersistenceState,
        tasksPersistenceState: state.dashboard.tasksPersistenceState,
    };
};

export default connect(mapStateToProps, null)(DashboardScreen);

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {NotificationBar, useStyles, Icon, warningOutlinedIcon, DropDownTrigger, PopOver, withDropDownProvider} from '@ace-de/ui-components';
import {alfOverpaymentReasonTypes, alfInvoiceStatusTypes} from '@ace-de/eua-entity-types';

const InvoiceReadOnlyBanner = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateComponent = createTranslateShorthand('invoice_read_only_banner');
    const {approvedBy, approvedAt, approveReason, approveDescription, bcErrorDescription} = props;
    const {declinedAt, declinedBy, declineDescription, declineReason, invoiceStatus} = props;
    const translatedApproveReason = translate(`global.invoice_approve_reason.${approveReason?.toLowerCase()}`);
    const translatedDeclineReason = translate(`global.invoice_decline_reason.${declineReason?.toLowerCase()}`);

    const bannerContent = (
        <Fragment>
            {approveReason || declineReason ? (
                <DropDownTrigger>
                    <div>
                        <Icon
                            className={cx('global!ace-u-margin--right-8')}
                            icon={warningOutlinedIcon}
                        />
                    </div>
                    <PopOver alignment="end" hasBorder={true}>
                        <div className={cx('global!ace-u-padding--24', 'ace-u-typography--variant-body')}>
                            {invoiceStatus !== alfInvoiceStatusTypes.DECLINED
                                ? (
                                    <>
                                        <p>{translateComponent('banner_content.approve_reason', {approveReason: translatedApproveReason})}</p>
                                        <p>{translateComponent('banner_content.approve_description', {approveDescription})}</p>
                                    </>
                                )
                                : (
                                    <>
                                        <p>{translateComponent('banner_content.decline_reason', {declineReason: translatedDeclineReason})}</p>
                                        <p>{translateComponent('banner_content.decline_description', {declineDescription})}</p>
                                    </>
                                )}
                        </div>
                    </PopOver>
                </DropDownTrigger>
            ) : (
                <Icon
                    className={cx('global!ace-u-margin--right-8')}
                    icon={warningOutlinedIcon}
                />
            )}
            {invoiceStatus !== alfInvoiceStatusTypes.DECLINED && approvedBy
                ? (
                    translateComponent(`banner_content.approved${
                        approveReason
                            ? Object.values(alfOverpaymentReasonTypes).includes(approveReason)
                                ? '_overpayment'
                                : '_partial_payment'
                            : ''
                    }`, {approvedBy, approvedAt: approvedAt ? moment(approvedAt).format('DD.MM.YYYY') : ''})
                ) : invoiceStatus === alfInvoiceStatusTypes.DECLINED && declinedBy
                    ? (
                        translateComponent(`banner_content.declined`, {
                            declinedBy,
                            declinedAt: declinedAt ? moment(declinedAt).format('DD.MM.YYYY') : '',
                        })
                    )
                    : (
                        translateComponent('banner_content.automatically_approved')
                    )}
        </Fragment>
    );

    return (
        <NotificationBar type={bcErrorDescription ? 'negative' : 'information'}>
            <div
                className={cx([
                    'ace-u-width--full',
                    'ace-u-margin--16-0',
                    'ace-u-flex',
                    'ace-u-flex--justify-center',
                    'ace-u-typography--variant-body',
                ])}
            >
                {bcErrorDescription ? (
                    <p className="ace-u-typography--color-contrast">
                        {translateComponent('banner_content.bc_error_message', {bcErrorMessage: bcErrorDescription})}
                    </p>
                ) : bannerContent}
            </div>
        </NotificationBar>
    );
};

InvoiceReadOnlyBanner.propTypes = {
    approvedBy: PropTypes.string,
    approvedAt: PropTypes.string,
    approveReason: PropTypes.string,
    approveDescription: PropTypes.string,
    bcErrorDescription: PropTypes.string,
    declinedAt: PropTypes.string,
    declinedBy: PropTypes.string,
    declineDescription: PropTypes.string,
    declineReason: PropTypes.string,
    invoiceStatus: PropTypes.string,
};

InvoiceReadOnlyBanner.defaultProps = {
    approvedBy: '',
    approvedAt: '',
    approveReason: '',
    approveDescription: '',
    bcErrorDescription: '',
    declinedAt: '',
    declinedBy: '',
    declineDescription: '',
    declineReason: '',
    invoiceStatus: '',
};

export default withDropDownProvider(InvoiceReadOnlyBanner);

import {call, select, take, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {Task} from '@ace-de/eua-entity-types';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import * as taskActionTypes from '../../tasks/taskActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import updateInvoice from '../../invoices/sagas/updateInvoice';
import updateServiceCase from './updateServiceCase';

const serviceCaseDocumentUploadFlow = function* serviceCaseDocumentUploadFlow() {
    const {serviceManager} = yield select(state => state.application);

    while (true) {
        yield take([serviceCaseActionTypes.INITIATE_DOCUMENT_UPLOAD_FLOW]);
        yield* openModal(modalIds.SERVICE_CASE_DOCUMENT_UPLOAD_MODAL);

        const chosenAction = yield take([
            serviceCaseActionTypes.DECLINE_SERVICE_CASE_DOCUMENT_UPLOAD,
            serviceCaseActionTypes.CONFIRM_SERVICE_CASE_DOCUMENT_UPLOAD,
        ]);

        if (chosenAction.type === serviceCaseActionTypes.CONFIRM_SERVICE_CASE_DOCUMENT_UPLOAD) {
            const {attachments, serviceCaseId, taskData = null} = chosenAction.payload;
            const invoiceIds = attachments.map(attachment => attachment.invoiceId).reduce((accumulator, invoiceId) => {
                if (!accumulator.includes(invoiceId)) {
                    accumulator.push(invoiceId);
                }
                return accumulator;
            }, []);
            const {serviceCaseDocuments} = yield select(state => state.serviceCases);

            yield* invoiceIds.map(invoiceId => {
                const invoiceAttachments = attachments.filter(attachment => (
                    attachment.invoiceId === invoiceId
                ));
                const existingAttachments = invoiceId === 'service-case'
                    ? serviceCaseDocuments.filter(document => !document.invoiceId)
                    : serviceCaseDocuments.filter(document => document.invoiceId === invoiceId);

                if (invoiceId === 'service-case') {
                    return call(updateServiceCase, {
                        caller: serviceCaseActionTypes.SUBMIT_UPLOADED_DOCUMENTS,
                        serviceCaseId,
                        serviceCaseData: {attachments: [...existingAttachments, ...invoiceAttachments]},
                    });
                }

                return call(updateInvoice, {
                    caller: serviceCaseActionTypes.SUBMIT_UPLOADED_DOCUMENTS,
                    invoiceId,
                    invoiceData: {attachments: [...existingAttachments, ...invoiceAttachments]},
                });
            });

            if (taskData) {
                const ecsTaskManagementService = serviceManager.loadService('ecsTaskManagementService');

                yield fork(
                    fetchRequest,
                    taskActionTypes.CREATE_TASK_REQUEST,
                    ecsTaskManagementService.createTask,
                    {
                        taskDTO: Task.objectToDTO(taskData),
                    },
                );
            }
        }

        yield* closeModal(modalIds.SERVICE_CASE_DOCUMENT_UPLOAD_MODAL);
    }
};

export default serviceCaseDocumentUploadFlow;

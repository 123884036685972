import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter, prepareSearchQueryParams, resolveRoute} from '@computerrock/formation-router';
import {closeIcon, newCaseIcon, Icon, ScreenTitle, useStyles, Pill, InteractiveIcon, linkIcon} from '@ace-de/ui-components';
import {alfInvoiceTypes} from '@ace-de/eua-entity-types';
import routePaths from '../../routePaths';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import invoiceStatusPillColorMap from '../invoiceStatusPillColorMap';
import scOverviewScreenTabs from '../../service-cases/scOverviewScreenTabs';
import goToParentRoute from '../../utils/goToParentRoute';

const InvoiceHeader = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateHeader = createTranslateShorthand('invoice_header');
    const {invoice, match, serviceCase, history} = props;
    const isOverviewScreen = match.path === routePaths.INVOICE_OVERVIEW;

    const redirectToServiceCaseOverview = () => {
        if (serviceCase?.id) {
            const {pathname, search} = resolveRoute(routePaths.SERVICE_CASES_OVERVIEW,
                {serviceCaseId: serviceCase.id},
                {search: prepareSearchQueryParams({activeTab: scOverviewScreenTabs.OVERVIEW})});
            window.open(pathname + search, '_blank');
        }
    };

    if (!invoice || !serviceCase) return null;

    return (
        <Fragment>
            <ScreenTitle className={cx('ace-c-screen-title--is-sticky')}>
                <div className={cx('global!ace-u-flex', 'global!ace-u-flex--align-center')}>
                    <Icon
                        icon={newCaseIcon}
                        className={cx([
                            'ace-c-icon--color-contrast',
                            'global!ace-u-margin--right-8',
                        ])}
                    />
                    <div
                        className={cx([
                            'global!ace-u-margin--right-8',
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-typography--color-contrast',
                        ])}
                    >
                        {translateHeader('screen_title.invoices')}
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body',
                            'global!ace-u-typography--color-contrast',
                        ])}
                    >
                        {invoice.id || '-'}
                    </div>
                </div>
            </ScreenTitle>
            <div
                className={cx([
                    'global!ace-u-flex--grow-1',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-margin--left-4',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    {isOverviewScreen && (
                        <div
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--left-16',
                                'global!ace-u-flex',
                            ])}
                        >
                            {translateHeader('label.external_invoice_id')}:&nbsp;
                            <span
                                className={cx('ace-u-typography--variant-body')}
                            >
                                {invoice.externalInvoiceNumber || '-'}
                            </span>
                        </div>
                    )}
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--left-16',
                            'global!ace-u-flex',
                        ])}
                    >
                        {translateHeader('label.type')}:&nbsp;
                        <span
                            className={cx('ace-u-typography--variant-body')}
                        >
                            {invoice.type || '-'}
                        </span>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--left-16',
                            'global!ace-u-flex',
                        ])}
                    >
                        {translateHeader('label.client')}:&nbsp;
                        <span
                            className={cx('ace-u-typography--variant-body')}
                        >
                            {serviceCase.client || '-'}
                        </span>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--left-16',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-flex--justify-content-center',
                            'global!ace-u-flex',
                        ])}
                    >
                        {translateHeader('label.status')}:&nbsp;
                        <Pill type={invoiceStatusPillColorMap[invoice.subStatus || invoice.status]}>
                            {translate(`global.invoice_status.${invoice.subStatus
                                ? invoice.subStatus.toLowerCase()
                                : invoice.status.toLowerCase()}`)}
                        </Pill>
                    </div>
                    {isOverviewScreen && (
                        <>
                            <div
                                className={cx([
                                    'global!ace-u-typography--variant-body-bold',
                                    'global!ace-u-margin--left-16',
                                    'global!ace-u-flex',
                                ])}
                            >
                                {translateHeader('label.created_by')}:&nbsp;
                                <span
                                    className={cx('ace-u-typography--variant-body')}
                                >
                                    {invoice.creatorName || '-'}
                                </span>
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-typography--variant-body-bold',
                                    'global!ace-u-margin--left-16',
                                    'global!ace-u-flex',
                                ])}
                            >
                                {translateHeader('label.created_at')}:&nbsp;
                                <span
                                    className={cx('ace-u-typography--variant-body')}
                                >
                                    {invoice.createdAt || '-'}
                                </span>
                            </div>
                        </>
                    ) }
                    {!isOverviewScreen && (
                        <div
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--left-16',
                                'global!ace-u-flex',
                            ])}
                        >
                            {translateHeader(`label.${invoice.type === alfInvoiceTypes.VKR ? 'debtor' : 'creditor'}`)}:&nbsp;
                            <span
                                className={cx('ace-u-typography--variant-body')}
                            >
                                {invoice.accountParty?.name || '-'}
                            </span>
                        </div>
                    )}
                    <div
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--left-16',
                            'global!ace-u-flex',
                        ])}
                    >
                        {translateHeader('label.case_id')}:&nbsp;
                        <InteractiveIcon
                            className={cx([
                                'ace-c-interactive-icon--highlight',
                                'ace-c-interactive-icon--primary',
                            ])}
                            icon={linkIcon}
                            onClick={redirectToServiceCaseOverview}
                        >
                            {`${serviceCase.prefix}-${invoice.serviceCaseId}` || '-'}
                        </InteractiveIcon>
                    </div>
                </div>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--right-32',
                    ])}
                >
                    <Icon
                        icon={closeIcon}
                        onClick={() => goToParentRoute({
                            currentPath: match.path,
                            history,
                            pathParams: {
                                serviceCaseId: match.params.serviceCaseId,
                            },
                            toParams: {
                                search: prepareSearchQueryParams({activeTab: scOverviewScreenTabs.OVERVIEW}),
                            },
                        })
                        }
                    />
                </div>
            </div>
        </Fragment>
    );
};

InvoiceHeader.propTypes = {
    invoice: PropTypes.object,
    serviceCase: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

InvoiceHeader.defaultProps = {
    invoice: null,
};

const mapStateToProps = (state, props) => {
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: getServiceCase(state, props),
    };
};

export default withRouter(connect(mapStateToProps)(InvoiceHeader));

import {produce} from 'immer';
import {Invoice, efLocationTypes, LeistLocation, InvoiceLine, AccountParty, InvoiceSubmission, persistenceStates} from '@ace-de/eua-entity-types';
import * as invoiceActionTypes from './invoiceActionTypes';

const initialState = {
    invoices: {},
    selectedInvoiceUploadedDocument: null,
    services: [],
    invoiceSearchResults: [],
    invoiceSearchResultsCount: 0,
    accountPartyOptions: [],
    invoiceSubmission: null,
    searchPersistenceState: persistenceStates.READY,
    isMemberLocationMissing: false,
};

/**
 * Invoice reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const invoiceReducer = produce((draft, action) => {
    switch (action.type) {
        case invoiceActionTypes.STORE_INVOICES: {
            const {invoiceDTOs} = action.payload;
            invoiceDTOs.forEach(invoiceDTO => {
                const invoice = draft.invoices[invoiceDTO.id];
                if (!invoice) {
                    const newInvoice = new Invoice().fromDTO(invoiceDTO);
                    draft.invoices[newInvoice.id] = newInvoice;
                    return;
                }
                draft.invoices[invoiceDTO.id] = invoice.fromDTO(invoiceDTO);
            });
            break;
        }

        case invoiceActionTypes.STORE_INVOICE_LINES: {
            const {invoiceLineDTO, invoiceId} = action.payload;
            draft.invoices[invoiceId].lines.push(new InvoiceLine().fromDTO(invoiceLineDTO));
            break;
        }

        case invoiceActionTypes.STORE_SELECTED_INVOICE_UPLOADED_DOCUMENT: {
            const {attachment} = action.payload;
            draft.selectedInvoiceUploadedDocument = attachment;
            break;
        }

        case invoiceActionTypes.SET_INVOICE_PARTNER_LOCATION_CANDIDATES: {
            const {arcGISLocationCandidateDTOs, invoiceId, searchQueryString} = action.payload;
            const invoice = draft.invoices[invoiceId];
            invoice[`partnerLocationSearchQuery`] = searchQueryString;
            invoice[`partnerLocationCandidates`] = arcGISLocationCandidateDTOs
                .map(arcGISLocationCandidateDTO => {
                    const locationCandidate = new LeistLocation().fromDTO(arcGISLocationCandidateDTO);
                    return locationCandidate.setLocationType(efLocationTypes.SELECT_LOCATION);
                });
            break;
        }

        case invoiceActionTypes.STORE_SERVICES: {
            const {serviceDTOs} = action.payload;
            draft.services = serviceDTOs;
            break;
        }

        case invoiceActionTypes.STORE_INVOICES_SEARCH_RESULTS: {
            const {invoiceDTOs, totalCount} = action.payload;
            draft.invoiceSearchResults = invoiceDTOs.map(invoiceDTO => (new Invoice().fromDTO(invoiceDTO)));
            draft.invoiceSearchResultsCount = typeof totalCount === 'number' ? totalCount : 0;
            break;
        }

        case invoiceActionTypes.SET_INVOICE_PERSISTENCE_STATE: {
            const {invoiceId, persistenceState} = action.payload;
            const invoice = draft.invoices[invoiceId];
            if (invoice) {
                draft.invoices[invoiceId] = invoice.setPersistenceState(persistenceState);
            }
            break;
        }

        case invoiceActionTypes.STORE_INVOICE_RELEVANT_LOCATIONS_DETAILS: {
            const {invoiceId, additionalLocationDetails} = action.payload;
            const invoice = draft.invoices[invoiceId];
            if (invoice && Object.keys(additionalLocationDetails).length > 0) {
                Object.keys(additionalLocationDetails).forEach(locationDetailKey => {
                    invoice[locationDetailKey] = additionalLocationDetails[locationDetailKey];
                });
                draft.invoices[invoiceId] = invoice;
            }
            break;
        }
        case invoiceActionTypes.STORE_INVOICE_READ_ONLY_APPROVAL_DATA: {
            const {invoiceId, approvedBy, approvedAt, declinedBy, declinedAt} = action.payload;
            const invoice = draft.invoices[invoiceId];
            if (invoice) {
                invoice.approvedBy = approvedBy;
                invoice.approvedAt = approvedAt;
                invoice.declinedBy = declinedBy;
                invoice.declinedAt = declinedAt;
                draft.invoices[invoiceId] = invoice;
            }
            break;
        }

        case invoiceActionTypes.STORE_ACCOUNT_PARTY_OPTIONS: {
            const {accountPartyDTOs} = action.payload;
            draft.accountPartyOptions = accountPartyDTOs.map(accountPartyDTO => (
                new AccountParty().fromDTO(accountPartyDTO)
            ));
            break;
        }

        case invoiceActionTypes.REMOVE_DELETED_INVOICE: {
            const {invoiceId} = action.payload;
            const invoiceList = draft.invoices;
            if (invoiceList && invoiceId) {
                delete invoiceList[invoiceId];
                draft.invoices = invoiceList;
            }
            break;
        }

        case invoiceActionTypes.SET_UPDATED_LOCATIONS: {
            const {invoiceId, updatedLocations} = action.payload;
            const invoice = draft.invoices[invoiceId];
            if (invoice) {
                invoice.locations = [...updatedLocations];
                draft.invoices[invoiceId] = invoice;
            }
            break;
        }

        case invoiceActionTypes.SET_INVOICE_FIXED_PRICE: {
            const {invoiceId, fixedPrice} = action.payload;
            const invoice = draft.invoices[invoiceId];
            if (invoice) {
                invoice.fixedPrice = fixedPrice;
                draft.invoices[invoiceId] = invoice;
            }
            break;
        }

        case invoiceActionTypes.STORE_INVOICE_SUBMISSION: {
            const {invoiceSubmissionDTO} = action.payload;
            draft.invoiceSubmission = new InvoiceSubmission().fromDTO(invoiceSubmissionDTO);
            break;
        }

        case invoiceActionTypes.SET_INVOICE_RELEVANT_LOCATIONS_PERSISTENCE_STATE: {
            const {persistenceState} = action.payload;
            draft.searchPersistenceState = persistenceState;
            break;
        }

        case invoiceActionTypes.SET_MISSING_MEMBER_LOCATION: {
            const {isMemberLocationMissing} = action.payload;
            draft.isMemberLocationMissing = isMemberLocationMissing;
            break;
        }

        default:
        //  no-op
    }
    return draft;
}, initialState);

export default invoiceReducer;

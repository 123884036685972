import {select, call, fork, take, put} from 'redux-saga/effects';
import {alfRelevantLocationTypes, LeistLocation, alfLocationProviderTypes, persistenceStates} from '@ace-de/eua-entity-types';
import {arcGISTravelModeTypes} from '@ace-de/eua-arcgis-rest-client';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as invoiceActionTypes from '../invoiceActionTypes';

const relevantLocationsCombinations = {
    [alfRelevantLocationTypes.SERVICE]: [
        alfRelevantLocationTypes.TOWING,
        alfRelevantLocationTypes.FINAL_TOWING,
    ],
    [alfRelevantLocationTypes.DAMAGE]: [
        alfRelevantLocationTypes.ACE_PARTNER,
        alfRelevantLocationTypes.MEMBER,
        alfRelevantLocationTypes.SERVICE,
    ],
    [alfRelevantLocationTypes.TOWING]: [alfRelevantLocationTypes.MEMBER, alfRelevantLocationTypes.ACE_PARTNER],
    [alfRelevantLocationTypes.FINAL_TOWING]: [alfRelevantLocationTypes.MEMBER, alfRelevantLocationTypes.ACE_PARTNER],
    [alfRelevantLocationTypes.ACE_PARTNER]: [
        alfRelevantLocationTypes.MEMBER,
        alfRelevantLocationTypes.DESTINATION,
    ],
    [alfRelevantLocationTypes.DESTINATION]: [alfRelevantLocationTypes.MEMBER],
};

const combinePins = (locations, relevantLocationType, alternativeTypeName) => {
    if (!locations || !relevantLocationType) return null;
    const referentialPoint = locations.find(location => relevantLocationType === location.type);
    if (!referentialPoint) return null;
    const combinedWith = [];
    const combinedLocations = [];
    locations.forEach(location => {
        if (relevantLocationsCombinations[referentialPoint.type].includes(location.type)
            && referentialPoint.coordinates?.longitude === location.coordinates?.longitude
            && referentialPoint.coordinates?.latitude === location.coordinates?.latitude
        ) {
            combinedWith.push(location.type);
            combinedLocations.push({
                ...referentialPoint,
                type: `COMBINED_${alternativeTypeName || relevantLocationType}_AND_${location.type}`,
            });
        }
    });
    if (combinedWith.length > 0) {
        combinedWith.push(relevantLocationType);
        return {
            combinedWith,
            combinedLocations,
        };
    }
    return null;
};

const fetchInvoiceRelevantLocations = function* fetchInvoiceRelevantLocations({payload}) {
    const {invoiceId} = payload;
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');
    const {invoices} = yield select(state => state.invoices);
    const {serviceCases} = yield select(state => state.serviceCases);
    const invoice = invoices[invoiceId];
    const serviceCase = serviceCases[invoice.serviceCaseId];
    const {member, damage} = serviceCase;
    const arcGISMap = yield call(arcGISMapService.getMap, 'invoice-relevant-locations-map');
    const {locations, lines} = invoice;
    const additionalLocationDetails = {};

    let hasPolygon = false;
    let providerLocation = null;
    let memberCoordinates = member?.personalDetails?.coordinates;

    if (!invoice || !arcGISMap) return;
    const invoiceRelevantLocationsServiceAreas = yield call(arcGISMap.getLayer, 'invoice-relevant-locations-service-areas');

    if (!invoiceRelevantLocationsServiceAreas) return;

    const acePartnerLocation = locations?.find(location => location.type === alfRelevantLocationTypes.ACE_PARTNER)
        || null;
    const mainServicePartnerAddress = lines[0]?.partnerAddress || null;

    yield put({
        type: invoiceActionTypes.SET_INVOICE_RELEVANT_LOCATIONS_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.PENDING},
    });

    yield put({
        type: invoiceActionTypes.SET_MISSING_MEMBER_LOCATION,
        payload: {isMemberLocationMissing: false},
    });

    // In case member coordinates are missing (MOA cases)
    if (member?.personalDetails?.address && (!memberCoordinates?.latitude || !memberCoordinates?.longitude)) {
        yield fork(
            fetchRequest,
            invoiceActionTypes.FETCH_MEMBER_LOCATION_GEOLOCATION_REQUEST,
            arcGISRESTService.searchAddressLocation,
            {
                singleLine: member?.personalDetails?.address?.displayAddress,
                forStorage: true,
            },
        );

        const memberGeolocationResponse = yield take([
            invoiceActionTypes.FETCH_MEMBER_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED,
            invoiceActionTypes.FETCH_MEMBER_LOCATION_GEOLOCATION_REQUEST_FAILED,
        ]);

        let isMemberLocationMissing = true;

        if (!memberGeolocationResponse.error) {
            const {response} = memberGeolocationResponse.payload;
            const {arcGISLocationCandidateDTOs} = response;

            if (arcGISLocationCandidateDTOs.length) {
                const memberGeolocation = arcGISLocationCandidateDTOs[0];
                memberCoordinates = {
                    ...memberGeolocation?.coordinates,
                    locationProvider: alfLocationProviderTypes.ARCGIS,
                };
                isMemberLocationMissing = false;
            }
        }

        if (isMemberLocationMissing) {
            yield put({
                type: invoiceActionTypes.SET_MISSING_MEMBER_LOCATION,
                payload: {isMemberLocationMissing: true},
            });
        }
    }

    // In case partnerLocation coordinates are not from ARCGIS
    if (acePartnerLocation
        && (acePartnerLocation.locationProvider !== alfLocationProviderTypes.ARCGIS
            || !acePartnerLocation.coordinates)
    ) {
        yield fork(
            fetchRequest,
            invoiceActionTypes.FETCH_ACE_PARTNER_LOCATION_GEOLOCATION_REQUEST,
            arcGISRESTService.searchAddressLocation,
            {
                singleLine: mainServicePartnerAddress,
                forStorage: true,
            },
        );

        const acePartnerGeolocationResponse = yield take([
            invoiceActionTypes.FETCH_ACE_PARTNER_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED,
            invoiceActionTypes.FETCH_ACE_PARTNER_LOCATION_GEOLOCATION_REQUEST_FAILED,
        ]);

        if (!acePartnerGeolocationResponse.error) {
            const {response} = acePartnerGeolocationResponse.payload;
            const {arcGISLocationCandidateDTOs} = response;
            const partnerGeolocation = arcGISLocationCandidateDTOs[0];
            providerLocation = {
                ...acePartnerLocation,
                ...(partnerGeolocation?.coordinates ? {coordinates: partnerGeolocation?.coordinates} : []),
                locationProvider: alfLocationProviderTypes.ARCGIS,
            };
        }
    }
    if (acePartnerLocation && acePartnerLocation.locationProvider === alfLocationProviderTypes.ARCGIS) {
        providerLocation = acePartnerLocation;
    }

    if (mainServicePartnerAddress && !acePartnerLocation) {
        yield fork(
            fetchRequest,
            invoiceActionTypes.FETCH_PARTNER_LOCATION_GEOLOCATION_REQUEST,
            arcGISRESTService.searchAddressLocation,
            {
                singleLine: mainServicePartnerAddress,
                forStorage: true,
            },
        );

        const mainServiceLocationResponse = yield take([
            invoiceActionTypes.FETCH_PARTNER_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED,
            invoiceActionTypes.FETCH_PARTNER_LOCATION_GEOLOCATION_REQUEST_FAILED,
        ]);

        if (!mainServiceLocationResponse.error) {
            const {response} = mainServiceLocationResponse.payload;
            const {arcGISLocationCandidateDTOs} = response;
            const mainServiceLocation = new LeistLocation().fromDTO(arcGISLocationCandidateDTOs[0]);
            if (mainServiceLocation) {
                providerLocation = {
                    address: mainServiceLocation.address,
                    coordinates: mainServiceLocation.coordinates,
                    type: alfRelevantLocationTypes.ACE_PARTNER,
                    locationProvider: alfLocationProviderTypes.ARCGIS,
                    id: `${mainServiceLocation.coordinates?.latitude}-${mainServiceLocation.coordinates?.longitude}` || null,
                };
            }
        }
    }
    const spacialRelevantLocations = locations?.length > 0
        ? locations.map((location, idx) => {
            if (location.type === alfRelevantLocationTypes.ACE_PARTNER
                && idx === locations.findIndex(locationItem => {
                    return locationItem.type === alfRelevantLocationTypes.ACE_PARTNER;
                })
                && providerLocation
            ) {
                return providerLocation;
            }
            return location;
        })
        : [
            {
                address: member.personalDetails?.address || null,
                coordinates: memberCoordinates || null,
                type: alfRelevantLocationTypes.MEMBER,
                locationProvider: alfLocationProviderTypes.ARCGIS,
                id: member.membershipNo,
            },
            ...(damage && damage.location
                ? [{
                    address: damage.location.address || null,
                    coordinates: damage.location.coordinates || null,
                    type: alfRelevantLocationTypes.DAMAGE,
                    locationProvider: alfLocationProviderTypes.ARCGIS,
                    id: `${damage.location?.coordinates?.latitude}-${damage.location?.coordinates?.longitude}` || null,
                }]
                : []
            ),
            ...(providerLocation ? [providerLocation] : []),
        ];

    if (!spacialRelevantLocations.find(spacialRelevantLocation => {
        return spacialRelevantLocation.type === alfRelevantLocationTypes.ACE_PARTNER;
    }) && providerLocation) {
        spacialRelevantLocations.push(providerLocation);
    }

    const serviceLocation = spacialRelevantLocations.find(spacialRelevantLocation => {
        return spacialRelevantLocation.type === alfRelevantLocationTypes.SERVICE;
    });

    if (!spacialRelevantLocations.find(spacialRelevantLocation => {
        return spacialRelevantLocation.type === alfRelevantLocationTypes.DAMAGE;
    }) && damage?.location) {
        spacialRelevantLocations.push({
            address: damage.location.address || null,
            coordinates: damage.location.coordinates || null,
            type: alfRelevantLocationTypes.DAMAGE,
            locationProvider: alfLocationProviderTypes.ARCGIS,
            id: `${damage.location?.coordinates?.latitude}-${damage.location?.coordinates?.longitude}` || null,
        });
    }

    if (!damage?.location && !!serviceLocation) {
        spacialRelevantLocations.push({
            address: serviceLocation.address || null,
            coordinates: serviceLocation.coordinates || null,
            type: alfRelevantLocationTypes.DAMAGE,
            locationProvider: alfLocationProviderTypes.ARCGIS,
            id: `${serviceLocation.coordinates?.latitude}-${serviceLocation.coordinates?.longitude}` || null,
        });
    }

    const recalculateCoordinates = [];
    let locationsPool = [];

    spacialRelevantLocations.forEach((spatialReferenceLocation, idx) => {
        if ((spatialReferenceLocation.locationProvider === alfLocationProviderTypes.GOOGLE
                || spatialReferenceLocation.locationProvider === alfLocationProviderTypes.MANUAL
                || (!spatialReferenceLocation.coordinates?.longitude
                    || !spatialReferenceLocation.coordinates?.latitude))
            && (
                spatialReferenceLocation.address && (
                    spatialReferenceLocation.address.formattedAddress
                    || (
                        spatialReferenceLocation.address.street
                        && spatialReferenceLocation.address.city
                        && spatialReferenceLocation.address.postCode
                    )
                )
            )) {
            recalculateCoordinates.push({
                'OBJECTID': idx, // should always be OBJECTID (not FID) for searchBulkAddressLocations
                'SingleLine': spatialReferenceLocation.formattedAddress
                    || [spatialReferenceLocation.address.street,
                        spatialReferenceLocation.address.city,
                        spatialReferenceLocation.address.postCode].join(', '),
            });
        }
    });

    if (recalculateCoordinates.length > 0) {
        yield fork(
            fetchRequest,
            invoiceActionTypes.FETCH_SPATIAL_LOCATION_GEOLOCATIONS_REQUEST,
            arcGISRESTService.searchBulkAddressLocations,
            {
                addressList: recalculateCoordinates,
                returnRoutes: false,
            },
        );

        const locationGeolocationResponse = yield take([
            invoiceActionTypes.FETCH_SPATIAL_LOCATION_GEOLOCATIONS_REQUEST_SUCCEEDED,
            invoiceActionTypes.FETCH_SPATIAL_LOCATION_GEOLOCATIONS_REQUEST_FAILED,
        ]);

        if (!locationGeolocationResponse.error) {
            const {response} = locationGeolocationResponse.payload;
            const {arcGISGeocodingResults} = response;
            locationsPool = spacialRelevantLocations.map((spacialRelevantLocation, idx) => {
                const arcGISGeocodingCandidateDTO = arcGISGeocodingResults.find(arcGISGeocodingResult => {
                    return arcGISGeocodingResult.resultId === idx;
                });
                if (arcGISGeocodingCandidateDTO) {
                    return {
                        ...spacialRelevantLocation,
                        coordinates: arcGISGeocodingCandidateDTO.coordinates,
                        address: arcGISGeocodingCandidateDTO.address,
                        locationProvider: alfLocationProviderTypes.ARCGIS,
                    };
                }
                return spacialRelevantLocation;
            });
        }
    }
    if (locationsPool.length === 0 && recalculateCoordinates.length === 0) {
        locationsPool = spacialRelevantLocations;
    }

    // determine relevant coordinates
    const latitudes = locationsPool.map(location => {
        return location?.coordinates?.latitude;
    });
    const longitudes = locationsPool.map(location => {
        return location?.coordinates?.longitude;
    });

    const spatialReference = invoiceRelevantLocationsServiceAreas.getServiceFeatureLayer().spatialReference;
    const sortedLatitudes = latitudes.filter(latitude => !!latitude).sort((a, b) => a - b);
    const sortedLongitudes = longitudes.filter(longitude => !!longitude).sort((a, b) => a - b);

    // In case we have more than one location
    if (sortedLongitudes.length > 1 && sortedLatitudes.length > 1) {
        additionalLocationDetails.relevantLocationMapExtent = arcGISMap.createMapExtentFromLocations(
            sortedLongitudes[0],
            sortedLatitudes[0],
            sortedLongitudes[sortedLongitudes.length - 1],
            sortedLatitudes[sortedLatitudes.length - 1],
            spatialReference,
        );
    }

    // In case we have only damage location
    if (sortedLatitudes.length === 1 && sortedLongitudes.length === 1) {
        additionalLocationDetails.relevantLocationMapExtent = arcGISMap.createMapExtentFromLocations(
            sortedLongitudes[0] - 0.005,
            sortedLatitudes[0] - 0.005,
            sortedLongitudes[0] + 0.005,
            sortedLatitudes[0] + 0.005,
            spatialReference,
        );
    }

    if (!locationsPool.find(formattedRelevantLocation => {
        return formattedRelevantLocation.type === alfRelevantLocationTypes.MEMBER;
    }) && member) {
        locationsPool.push({
            address: member.personalDetails?.address || null,
            coordinates: memberCoordinates || null,
            type: alfRelevantLocationTypes.MEMBER,
            locationProvider: alfLocationProviderTypes.ARCGIS,
            id: member.membershipNo,
        });
    }

    const towingLocation = locationsPool.find(location => {
        return location?.type === alfRelevantLocationTypes.FINAL_TOWING;
    }) || locationsPool.find(location => {
        return location?.type === alfRelevantLocationTypes.TOWING;
    });

    const damageLocation = locationsPool.find(location => {
        return location?.type === alfRelevantLocationTypes.DAMAGE;
    }) || damage?.location;

    // Calculate round trip of provider
    if (providerLocation) {
        yield fork(
            fetchRequest,
            invoiceActionTypes.GET_INVOICE_RELEVANT_LOCATIONS_DISTANCES_REQUEST,
            arcGISRESTService.getMultipleStopsRoute,
            {
                stops: [
                    [providerLocation.coordinates?.longitude, providerLocation.coordinates?.latitude],
                    ...(damageLocation
                        ? [[damageLocation.coordinates?.longitude, damageLocation.coordinates?.latitude]]
                        : []
                    ),
                    ...(towingLocation
                        ? [[towingLocation.coordinates?.longitude, towingLocation.coordinates?.latitude]]
                        : []
                    ),
                    [providerLocation.coordinates?.longitude, providerLocation.coordinates?.latitude],
                ],
                travelModeType: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
            },
        );

        const routeCalculationActionResponse = yield take([
            invoiceActionTypes.GET_INVOICE_RELEVANT_LOCATIONS_DISTANCES_REQUEST_SUCCEEDED,
            invoiceActionTypes.GET_INVOICE_RELEVANT_LOCATIONS_DISTANCES_REQUEST_FAILED,
        ]);
        if (!routeCalculationActionResponse.error) {
            const {response} = routeCalculationActionResponse.payload;
            const {arcGISRouteDTO} = response;
            additionalLocationDetails.providerDamageTowingProviderDistance = arcGISRouteDTO.totalKilometers;
            additionalLocationDetails.roundTripRoute = arcGISRouteDTO;
        }
    }

    if (providerLocation?.externalId) {
        yield fork(
            fetchRequest,
            invoiceActionTypes.FILTER_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST,
            invoiceRelevantLocationsServiceAreas.filterFeaturesByAttribute,
            {
                ...(damageLocation && {referentialPoint: damageLocation.coordinates}),
                ...(towingLocation && {referentialPoint: towingLocation.coordinates}),
                where: `contractPa = '${providerLocation.externalId}'`,
                travelMode: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
            },
        );

        const filterContractPartnerServiceAreasResponseAction = yield take([
            invoiceActionTypes.FILTER_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_FAILED,
            invoiceActionTypes.FILTER_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_SUCCEEDED,
        ]);

        if (!filterContractPartnerServiceAreasResponseAction.error) {
            const {response} = filterContractPartnerServiceAreasResponseAction.payload;
            const {featureDTOs} = response;

            if (damageLocation && featureDTOs.length > 0) {
                additionalLocationDetails.damageLocationWithinArea = featureDTOs[0].containsDamageLocation;
            }
            if (towingLocation && featureDTOs.length > 0) {
                additionalLocationDetails.towingDestinationWithinArea = featureDTOs[0].containsDamageLocation;
            }

            if (featureDTOs.length > 0) {
                hasPolygon = true;
                additionalLocationDetails.filterQuery = {
                    ...(damageLocation && {referentialPoint: damageLocation.coordinates}),
                    ...(towingLocation && {referentialPoint: towingLocation.coordinates}),
                    where: `contractPa = '${providerLocation.externalId}'`,
                    travelMode: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
                };
            }
        }
    }

    if (!serviceCase.distanceResidenceToServiceLocation && damageLocation && memberCoordinates) {
        yield fork(
            fetchRequest,
            invoiceActionTypes.FETCH_DAMAGE_TO_RESIDENCE_DISTANCE_REQUEST,
            arcGISRESTService.getRoute,
            {
                startingPoint: {
                    longitude: memberCoordinates?.longitude,
                    latitude: memberCoordinates?.latitude,
                },
                destination: {
                    longitude: damageLocation.coordinates?.longitude,
                    latitude: damageLocation.coordinates?.latitude,
                },
            },
        );

        const damageToResidenceDistanceResponse = yield take([
            invoiceActionTypes.FETCH_DAMAGE_TO_RESIDENCE_DISTANCE_REQUEST_SUCCEEDED,
            invoiceActionTypes.FETCH_DAMAGE_TO_RESIDENCE_DISTANCE_REQUEST_FAILED,
        ]);

        if (!damageToResidenceDistanceResponse.error) {
            const {response} = damageToResidenceDistanceResponse.payload;
            const {arcGISRouteDTO} = response;
            additionalLocationDetails.residenceToDamageLocationDistance = arcGISRouteDTO.totalKilometers;
        }
    }
    if (hasPolygon) {
        additionalLocationDetails.polygonId = providerLocation?.externalId;
        try {
            yield call(invoiceRelevantLocationsServiceAreas.selectFeatureByAttribute, {
                where: `contractPa = '${providerLocation?.externalId}'`,
            });
            invoiceRelevantLocationsServiceAreas.show();
        } catch (e) {
            // no-op
        }
    }

    const combinedServiceLocations = combinePins(locationsPool, alfRelevantLocationTypes.SERVICE);
    const combinedDamageLocations = combinePins(locationsPool, alfRelevantLocationTypes.DAMAGE);
    const combinedTowingLocations = combinePins(locationsPool, alfRelevantLocationTypes.TOWING);
    const combinedFinalTowingLocations = combinePins(locationsPool, alfRelevantLocationTypes.FINAL_TOWING);
    const combinedAcePartnerLocations = combinePins(locationsPool, alfRelevantLocationTypes.ACE_PARTNER);
    const combinedDestinationLocations = combinePins(locationsPool, alfRelevantLocationTypes.DESTINATION);

    const combinedAndRegularLocations = [...(locationsPool || []),
        ...(combinedServiceLocations ? combinedServiceLocations.combinedLocations : []),
        ...(combinedDamageLocations ? combinedDamageLocations.combinedLocations : []),
        ...(combinedTowingLocations ? combinedTowingLocations.combinedLocations : []),
        ...(combinedFinalTowingLocations ? combinedFinalTowingLocations.combinedLocations : []),
        ...(combinedAcePartnerLocations ? combinedAcePartnerLocations.combinedLocations : []),
        ...(combinedDestinationLocations ? combinedDestinationLocations.combinedLocations : [])];

    const combinedExcludedPins = [
        ...(combinedServiceLocations ? combinedServiceLocations.combinedWith : []),
        ...(combinedDamageLocations ? combinedDamageLocations.combinedWith : []),
        ...(combinedTowingLocations ? combinedTowingLocations.combinedWith : []),
        ...(combinedFinalTowingLocations ? combinedFinalTowingLocations.combinedWith : []),
        ...(combinedAcePartnerLocations ? combinedAcePartnerLocations.combinedWith : []),
        ...(combinedDestinationLocations ? combinedDestinationLocations.combinedWith : []),
    ];

    // If we merged some pins or if the member location was missing
    if (combinedExcludedPins.length > 0 || combinedAndRegularLocations.length > locations.length) {
        yield put({
            type: invoiceActionTypes.SET_UPDATED_LOCATIONS,
            payload: {
                invoiceId: invoiceId,
                updatedLocations: combinedAndRegularLocations.filter(location => {
                    return !combinedExcludedPins.includes(location.type);
                }),
            },
        });
    }

    yield put({
        type: invoiceActionTypes.STORE_INVOICE_RELEVANT_LOCATIONS_DETAILS,
        payload: {invoiceId, additionalLocationDetails},
    });

    yield put({
        type: invoiceActionTypes.SET_INVOICE_RELEVANT_LOCATIONS_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.READY},
    });
};

export default fetchInvoiceRelevantLocations;

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {useHistory, resolveRoute} from '@computerrock/formation-router';
import {europeanCountries, alfClientTypes, alfAccountTypes, alfAddressTypes, /* alfBusinessRelationTypes, */ persistenceStates} from '@ace-de/eua-entity-types';
import {Panel, NoResultsBlock, ToggleTab, ToggleTabSwitch, useStyles, Paginator, LoaderSpinner, HighlightCircle} from '@ace-de/ui-components';
import {Button, ButtonIcon, ButtonPrimary} from '@ace-de/ui-components/buttons';
import {Form, InputField, SelectField, Option} from '@ace-de/ui-components/form';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {InteractiveIcon, resetIcon, infoAlertIcon, Icon, closeIcon, plusIcon, searchIcon, checkmarkIcon, editIcon, communicationIcon} from '@ace-de/ui-components/icons';
import * as contactActionTypes from './contactActionTypes';
import * as communicationActionTypes from '../communication/communicationActionTypes';
import routePaths from '../routePaths';
import config from '../config';

// todo uncomment businessRelations filter related code, once that filtering is implemented on NAV

const initialContactSearchParams = {
    client: alfClientTypes.ACE,
    ids: '',
    name: '',
    street: '',
    postCode: '',
    city: '',
    addressTypes: [],
    /* businessRelations: [], */
};

const ContactsSearchScreen = props => {
    const {cx} = useStyles();
    const history = useHistory();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateScreen = createTranslateShorthand('contacts_search_screen');
    const {contactsSearchResults, initiateContactCreation, contactsSearchResultsCount} = props;
    const {isContactsSearchError, isContactSearchPerformed, initiateAccountPartyCreation} = props;
    const {searchContacts, resetContactsSearchResults, initiateEditContact, initiateEditAccountParty} = props;
    const {initiateSendMessageFlow} = props;
    const {searchPersistenceState, bcErrorDescription} = props;
    const [formData, setFormData] = useState(initialContactSearchParams);
    const [savedFormDataForSearchResults, setSavedFormDataForSearchResults] = useState(formData);
    const [selectedContact, setSelectedContact] = useState(null);
    const [queryParams, setQueryParams] = useState(new URLSearchParams(history.location.search));

    const paginatorCount = Math.ceil(contactsSearchResultsCount / config.DEFAULT_PAGE_SIZE);
    const isSearchInProgress = searchPersistenceState === persistenceStates.PENDING;

    useEffect(() => () => resetContactsSearchResults(), [resetContactsSearchResults]);

    useEffect(() => {
        if (!selectedContact?.id) return;
        const updatedContact = contactsSearchResults.find(contact => contact.id === selectedContact.id);
        if (updatedContact) setSelectedContact({...updatedContact});
    }, [contactsSearchResults, selectedContact?.id]);

    useEffect(() => {
        if (typeof history.location.search === 'string') {
            const newQueryParams = new URLSearchParams(history.location.search);
            if (newQueryParams.toString() !== queryParams.toString()) {
                setQueryParams(newQueryParams);
            }
            setFormData({
                client: newQueryParams.get('client') || initialContactSearchParams.client,
                ids: newQueryParams.get('ids') || initialContactSearchParams.ids,
                name: newQueryParams.get('name') || initialContactSearchParams.name,
                street: newQueryParams.get('street') || initialContactSearchParams.street,
                postCode: newQueryParams.get('postCode') || initialContactSearchParams.postCode,
                city: newQueryParams.get('city') || initialContactSearchParams.city,
                addressTypes: newQueryParams.getAll('addressTypes').length > 0
                    ? newQueryParams.getAll('addressTypes')
                    : initialContactSearchParams.addressTypes,
                /* businessRelations: newQueryParams.getAll('businessRelations').length > 0
                    ? newQueryParams.getAll('businessRelations')
                    : initialContactSearchParams.businessRelations, */
            });
        }
    }, [history.location.search, queryParams]);

    const formatQueryParams = formData => {
        if (!formData) return;
        const searchQueryParams = new URLSearchParams();

        Object.keys(formData).forEach(formField => {
            if (formData[formField] !== undefined && formData[formField] !== '') {
                if (formField !== 'addressTypes' /* && formField !== 'businessRelations' */) {
                    searchQueryParams.append(`${formField}`, formData[formField]);
                }

                if ((formField === 'addressTypes' /* || formField === 'businessRelations' */)
                    && Array.isArray(formData[formField]) && formData[formField].length > 0) {
                    formData[formField].forEach(category => {
                        if (category !== '') searchQueryParams.append(formField, category);
                    });
                }
            }
        });

        return searchQueryParams;
    };

    const handleOnChange = (key, value) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleOnToggleSwitch = value => {
        handleOnChange('client', value);
        let isFormEmpty = true;
        Object.keys(savedFormDataForSearchResults).forEach(formField => {
            if (formField !== 'client'
                && (!!savedFormDataForSearchResults[formField] || !!queryParams.get(formField))) isFormEmpty = false;
        });
        if (isFormEmpty) return;

        const searchQueryParams = formatQueryParams({
            client: value,
            ids: savedFormDataForSearchResults.ids || queryParams.get('ids') || '',
            name: savedFormDataForSearchResults.name || queryParams.get('name') || '',
            street: savedFormDataForSearchResults.street || queryParams.get('street') || '',
            postCode: savedFormDataForSearchResults.postCode || queryParams.get('postCode') || '',
            city: savedFormDataForSearchResults.city || queryParams.get('city') || '',
            page: queryParams.get('page') || '',
            addressTypes: savedFormDataForSearchResults.addressTypes
                || (queryParams.getAll('addressTypes').length > 0 ? queryParams.getAll('addressTypes') : []),
            /* businessRelations: savedFormDataForSearchResults.businessRelations
                || (queryParams.getAll('businessRelations').length > 0
                ? queryParams.getAll('businessRelations') : []), */
        });

        history.push(resolveRoute(routePaths.CONTACTS, {}, {
            search: searchQueryParams.toString(),
        }));

        searchContacts({searchQueryParams});

        setSelectedContact(null);
    };

    const handleOnSubmit = () => {
        const searchQueryParams = formatQueryParams({
            ...formData,
        });

        if (searchQueryParams.size === 1 && searchQueryParams.has('client')) return;

        history.push(resolveRoute(routePaths.CONTACTS, {}, {
            search: searchQueryParams.toString(),
        }));

        searchContacts({searchQueryParams});
        setSelectedContact(null);
        setSavedFormDataForSearchResults(formData);
    };

    const handleContactCreation = () => {
        initiateContactCreation({
            client: formData.client,
        });
        setSelectedContact(null);
    };

    const handleEditContact = selectedContact => {
        initiateEditContact({
            client: formData.client,
            selectedContact,
        });
        setSelectedContact(null);
    };

    const handleStartCommunication = selectedContact => {
        initiateSendMessageFlow({
            selectedContact,
        });
    };

    const handleResetFilter = () => {
        setFormData(initialContactSearchParams);
        setSavedFormDataForSearchResults(initialContactSearchParams);
        history.push(resolveRoute(routePaths.CONTACTS));
        resetContactsSearchResults();
        setSelectedContact(null);
    };

    const handlePaginationPage = page => {
        const searchQueryParams = new URLSearchParams(queryParams);

        searchQueryParams.set('page', `${page}`);
        searchQueryParams.set('size', `${config.DEFAULT_PAGE_SIZE}`);
        if (!searchQueryParams.get('client')) searchQueryParams.append('client', formData.client);

        history.push(resolveRoute(routePaths.CONTACTS, {}, {search: searchQueryParams?.toString()}));

        searchContacts({searchQueryParams});
        setSelectedContact(null);
    };

    const renderAccountPartyButton = (accountPartyType, actionType, accountPartyId) => {
        return (
            <ButtonPrimary
                className={cx('global!ace-u-margin--left-24')}
                onClick={() => {
                    actionType === 'CREATE'
                        ? initiateAccountPartyCreation({
                            client: formData.client,
                            accountPartyType,
                            selectedContact,
                        })
                        : initiateEditAccountParty({
                            client: formData.client,
                            accountPartyType,
                            accountPartyId,
                        });
                }}
            >
                <Icon
                    icon={actionType === 'CREATE' ? plusIcon : editIcon}
                    className={cx([
                        'global!ace-c-icon--color-contrast',
                        'global!ace-u-margin--right-8',
                    ])}
                />
                {translateScreen(`button_label.${actionType.toLowerCase()}_${accountPartyType.toLowerCase()}`)}
            </ButtonPrimary>
        );
    };

    return (
        <Panel
            title={translateScreen('panel_title.contact_search')}
            className={cx('ace-c-panel--full-bleed-content')}
        >
            <div
                className={cx([
                    'global!ace-u-padding--0-32',
                    'global!ace-u-margin--bottom-32',
                    'global!ace-u-width--full',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <ToggleTabSwitch
                    name="clientBCSearch"
                    className={cx('global!ace-u-margin--bottom-24')}
                    onChange={value => handleOnToggleSwitch(value)}
                    value={queryParams.get('client') || formData.client}
                    isDisabled={isSearchInProgress}
                >
                    {Object.values(alfClientTypes).map(tab => (
                        <ToggleTab
                            key={tab}
                            name={`${tab.toLowerCase()}Tab`}
                            value={tab}
                        >
                            {tab}
                        </ToggleTab>
                    ))}
                </ToggleTabSwitch>
                <Button isDisabled={isSearchInProgress} onClick={handleContactCreation}>
                    <Icon
                        className={cx([
                            'global!ace-c-icon--color-highlight',
                            'global!ace-c-icon--s',
                            'global!ace-u-margin--right-8',
                        ])}
                        icon={plusIcon}
                    />
                    {translateScreen('button.create_contact')}
                </Button>
            </div>
            <div className={cx(['global!ace-u-padding--0-32', 'global!ace-u-width--full'])}>
                <Form name="contactSearch" onSubmit={handleOnSubmit}>
                    <div
                        className={cx([
                            'global!ace-u-margin--bottom-24',
                            'global!ace-u-width--full',
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-flex-end',
                            'global!ace-u-flex--justify-space-between',
                        ])}
                        onKeyDown={event => {
                            if (event.key === 'Enter') handleOnSubmit();
                        }}
                    >
                        {Object
                            .keys(formData)
                            .filter(formField => !['addressTypes'/* , 'businessRelations' */].includes(formField))
                            .map(formField => {
                                if (formField === 'client') {
                                    return null;
                                }
                                return (
                                    <div
                                        key={formField}
                                        className={cx([
                                            'global!ace-u-margin--right-24',
                                        ], {
                                            'global!ace-u-flex--basis-5': formField === 'postCode',
                                            'global!ace-u-flex--basis-20': formField !== 'postCode',
                                        })}
                                    >
                                        <InputField
                                            name={formField}
                                            label={translateScreen(`input_label.${formField}`)}
                                            value={formData[formField]}
                                            className={cx([
                                                'global!ace-u-width--full',
                                            ])}
                                            onChange={value => handleOnChange(formField, value)}
                                            isDisabled={isSearchInProgress}
                                        />
                                    </div>
                                );
                            })}
                        <div
                            key="addressTypes"
                            className={cx([
                                'global!ace-u-margin--right-24',
                                'global!ace-u-flex--basis-10',
                                'global!ace-u-min-width--0',
                            ])}
                        >
                            <SelectField
                                name="addressTypes"
                                value={formData.addressTypes}
                                isMultipleChoice={true}
                                className={cx('global!ace-u-width--full')}
                                label={translateScreen('input_label.address_type')}
                                onChange={value => handleOnChange('addressTypes', value)}
                                isDisabled={isSearchInProgress}
                            >
                                {Object.keys(alfAddressTypes).map(alfAddressType => (
                                    <Option
                                        name={`${alfAddressType.toLowerCase()}Option`}
                                        key={alfAddressType}
                                        value={alfAddressType}
                                    >
                                        {translate(`global.contact_address_type.${alfAddressType.toLowerCase()}`)}
                                    </Option>
                                ))}
                            </SelectField>
                        </div>
                        {/* <div
                            key="businessRelations"
                            className={cx([
                                'global!ace-u-margin--right-24',
                                'global!ace-u-flex--basis-15',
                            ])}
                        >
                            <SelectField
                                name="types"
                                label={translateScreen('input_label.business_relations')}
                                value={formData.businessRelations}
                                isMultipleChoice={true}
                                className={cx('global!ace-u-width--full')}
                                onChange={value => handleOnChange('businessRelations', value)}
                            >
                                {Object.values(alfBusinessRelationTypes)
                                    // eslint-disable-next-line max-len
                                    .filter(businessRelationType => (
                                    businessRelationType !== alfBusinessRelationTypes.MEMBER
                                    ).map(businessRelationType => (
                                        <Option
                                            name={`${businessRelationType.toLowerCase()}Option`}
                                            key={businessRelationType}
                                            value={businessRelationType}
                                        >
                                            {translateScreen(`select_option.${businessRelationType.toLowerCase()}`)}
                                        </Option>
                                    ))}
                            </SelectField>
                        </div> */}
                        <ButtonIcon
                            name="searchButton"
                            icon={searchIcon}
                            type="submit"
                            className={cx([
                                'global!ace-u-flex--basis-5',
                                'global!ace-u-width--64',
                                'global!ace-u-height--48',
                            ])}
                            isDisabled={isSearchInProgress}
                        />
                    </div>
                </Form>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--justify-flex-end',
                    ])}
                >
                    <InteractiveIcon
                        icon={resetIcon}
                        className={cx([
                            'ace-c-interactive-icon--reverse',
                            'ace-c-interactive-icon--highlight',
                        ])}
                        onClick={handleResetFilter}
                        isDisabled={isSearchInProgress}
                    >
                        {translateScreen('interactive_icon_label.reset_search')}
                    </InteractiveIcon>
                </div>
                <p
                    className={cx([
                        'global!ace-u-margin--bottom-24',
                        'global!ace-u-typography--variant-h3',
                    ])}
                >
                    {translateScreen('panel_subtitle.results')}
                </p>
            </div>
            <Table qaIdent="contacts-search-results" className={cx('global!ace-u-padding--24')}>
                {(
                    (isContactSearchPerformed && contactsSearchResults.length === 0 && !isSearchInProgress)
                    || isContactsSearchError
                )
                && (
                <TableCaption>
                    <NoResultsBlock
                        colorVariant={!isContactsSearchError ? 'primary-highlight' : 'negative'}
                        icon={(
                            <Icon
                                className={cx('ace-c-icon--xxl', {
                                    'global!ace-c-icon--color-highlight': !isContactsSearchError,
                                    'global!ace-c-icon--color-warning': isContactsSearchError,
                                })}
                                icon={infoAlertIcon}
                            />
                                )}
                        description={!isContactsSearchError
                            ? translateScreen('no_results.description')
                            : translateScreen('results_error.description')
                                }
                        message={!isContactsSearchError
                            ? translateScreen('no_results.message')
                            : bcErrorDescription || translateScreen('results_error.message')
                        }
                    />
                </TableCaption>
                )}
                {isSearchInProgress && (
                    <TableCaption>
                        <HighlightCircle
                            className={cx([
                                'ace-c-highlight-circle--medium',
                                'ace-c-highlight-circle--primary-highlight',
                                'global!ace-u-margin--top-64',
                            ])}
                        >
                            <LoaderSpinner />
                        </HighlightCircle>
                        <p className={cx('global!ace-u-margin--top-16', 'global!ace-u-typography--variant-h4')}>
                            {translateScreen('loader_message.search_in_progress')}
                        </p>
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} qaIdentPart="contact-id" className={cx('ace-u-padding--left-32')}>
                            {translateScreen('table_data_row.contact_id')}
                        </TableCell>
                        <TableCell colSpan={1} qaIdentPart="contact-type">
                            {translateScreen('table_data_row.type')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="contact-business-relations">
                            {translateScreen('table_data_row.businessRelation')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="contact-name">
                            {translateScreen('table_data_row.name')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="contact-address-street">
                            {translateScreen('table_data_row.address')}
                        </TableCell>
                        <TableCell colSpan={1} qaIdentPart="contact-address-post-code">
                            {translateScreen('table_data_row.postCode')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="contact-address-city">
                            {translateScreen('table_data_row.city')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="contact-address-country">
                            {translateScreen('table_data_row.country')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="contact-creditor-id">
                            {translateScreen('table_data_row.creditor')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="contact-debtor-id" className={cx('ace-u-padding--right-32')}>
                            {translateScreen('table_data_row.debtor')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!isContactsSearchError && !isSearchInProgress && contactsSearchResults.map(contact => (
                        <TableRow
                            key={contact.id}
                            onClick={() => setSelectedContact(contact)}
                            className={cx({
                                'global!ace-u-typography--variant-body-bold': selectedContact?.id === contact.id,
                            })}
                            qaIdentPart={contact.id}
                        >
                            <TableCell colSpan={2} qaIdentPartPostfix={contact.id} qaIdentPart="contact-id" className={cx('ace-u-padding--left-32')}>
                                {contact.id || '-'}
                            </TableCell>
                            <TableCell colSpan={1} qaIdentPartPostfix={contact.id} qaIdentPart="contact-type">
                                {translateScreen(`contact_type.${contact.type.toLowerCase()}`)}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPartPostfix={contact.id} qaIdentPart="contact-business-relations">
                                {contact.businessRelations.length > 0
                                    ? contact.businessRelations.map((businessRelation, idx) => (
                                        `${translate(`global.business_relation_type.${businessRelation.toLowerCase()}`)}${idx < (contact.businessRelations.length - 1) ? ', ' : ''}`
                                    ))
                                    : '-'}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPartPostfix={contact.id} qaIdentPart="contact-name">
                                {contact.name || '-'}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPartPostfix={contact.id} qaIdentPart="contact-address-street">
                                {contact.address?.street || '-'}
                            </TableCell>
                            <TableCell colSpan={1} qaIdentPartPostfix={contact.id} qaIdentPart="contact-address-post-code">
                                {contact.address?.postCode || '-'}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPartPostfix={contact.id} qaIdentPart="contact-address-city">
                                {contact.address?.city || '-'}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPartPostfix={contact.id} qaIdentPart="contact-address-country">
                                {contact.address?.country
                                    ? Object.values(europeanCountries).find(country => {
                                        return country?.name === contact.address.country;
                                    }) ? translate(
                                            `global.country.${snakeCase(contact.address.country)}`,
                                        ) : translate('global.country.other')
                                    : '-'}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPartPostfix={contact.id} qaIdentPart="contact-creditor-id">
                                {contact.creditorId
                                    ? (
                                        <Icon
                                            className={cx('global!ace-c-icon--color-success')}
                                            icon={checkmarkIcon}
                                        />
                                    )
                                    : <Icon icon={closeIcon} />}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPartPostfix={contact.id} qaIdentPart="contact-debtor-id" className={cx('ace-u-padding--right-32')}>
                                {contact.debtorId
                                    ? (
                                        <Icon
                                            className={cx('global!ace-c-icon--color-success')}
                                            icon={checkmarkIcon}
                                        />
                                    )
                                    : <Icon icon={closeIcon} />}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div
                className={cx([
                    'global!ace-u-padding--right-64',
                    'global!ace-u-margin--32',
                    'global!ace-u-width--full',
                    'global!ace-u-flex',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-flex-end',
                ])}
            >
                <Button
                    isDisabled={!selectedContact}
                    onClick={() => handleEditContact(selectedContact)}
                >
                    <Icon
                        icon={editIcon}
                        className={cx([
                            'global!ace-u-margin--right-8', {
                                'global!ace-c-icon--color-disabled': !selectedContact,
                                'global!ace-c-icon--color-highlight': selectedContact,
                            },
                        ])}
                    />
                    {translateScreen('button_label.edit_contact')}
                </Button>
                {selectedContact && (
                    <div>
                        <Button
                            className={cx('global!ace-u-margin--left-24')}
                            onClick={() => { handleStartCommunication(selectedContact); }}
                        >
                            <Icon
                                icon={communicationIcon}
                                className={cx([
                                    'global!ace-c-icon--color-contrast',
                                    'global!ace-u-margin--right-8',
                                ])}
                            />
                            {translateScreen(`button_label.start_communication`)}
                        </Button>
                        {renderAccountPartyButton(
                            alfAccountTypes.CREDITOR,
                            selectedContact.creditorId ? 'EDIT' : 'CREATE',
                            selectedContact.creditorId,
                        )}
                        {renderAccountPartyButton(
                            alfAccountTypes.DEBTOR,
                            selectedContact.debtorId ? 'EDIT' : 'CREATE',
                            selectedContact.debtorId,
                        )}
                    </div>
                )}
            </div>
            {contactsSearchResultsCount > 0 && !isSearchInProgress && (
                <Paginator
                    page={+queryParams.get('page')}
                    count={paginatorCount}
                    onClick={page => handlePaginationPage(page)}
                />
            )}
        </Panel>
    );
};

ContactsSearchScreen.propTypes = {
    searchContacts: PropTypes.func.isRequired,
    contactsSearchResults: PropTypes.array,
    contactsSearchResultsCount: PropTypes.number,
    isContactsSearchError: PropTypes.bool,
    isContactSearchPerformed: PropTypes.bool,
    initiateContactCreation: PropTypes.func.isRequired,
    initiateEditContact: PropTypes.func.isRequired,
    initiateAccountPartyCreation: PropTypes.func.isRequired,
    resetContactsSearchResults: PropTypes.func.isRequired,
    initiateEditAccountParty: PropTypes.func.isRequired,
    initiateSendMessageFlow: PropTypes.func.isRequired,
    searchPersistenceState: PropTypes.string.isRequired,
    bcErrorDescription: PropTypes.string,
};

ContactsSearchScreen.defaultProps = {
    contactsSearchResults: [],
    contactsSearchResultsCount: 0,
    isContactsSearchError: false,
    isContactSearchPerformed: false,
    bcErrorDescription: '',
};

const mapStateToProps = state => {
    return {
        contactsSearchResults: state.contacts.contactsSearchResults,
        contactsSearchResultsCount: state.contacts.contactsSearchResultsCount,
        isContactsSearchError: state.contacts.isContactsSearchError,
        isContactSearchPerformed: state.contacts.isContactSearchPerformed,
        searchPersistenceState: state.contacts.searchPersistenceState,
        bcErrorDescription: state.contacts.errorDescription,
    };
};

const mapDispatchToProps = dispatch => ({
    searchContacts: payload => dispatch({
        type: contactActionTypes.SEARCH_CONTACTS,
        payload,
    }),
    initiateContactCreation: payload => dispatch({
        type: contactActionTypes.INITIATE_CREATE_CONTACT_MODAL_FLOW,
        payload,
    }),
    initiateEditContact: payload => dispatch({
        type: contactActionTypes.INITIATE_EDIT_CONTACT_MODAL_FLOW,
        payload,
    }),
    initiateAccountPartyCreation: payload => dispatch({
        type: contactActionTypes.INITIATE_CREATE_ACCOUNT_PARTY_MODAL_FLOW,
        payload,
    }),
    initiateEditAccountParty: payload => dispatch({
        type: contactActionTypes.INITIATE_EDIT_ACCOUNT_PARTY_FLOW,
        payload,
    }),
    resetContactsSearchResults: () => dispatch({
        type: contactActionTypes.RESET_CONTACT_SEARCH,
    }),
    initiateSendMessageFlow: payload => dispatch({
        type: communicationActionTypes.INITIATE_SEND_MESSAGE_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactsSearchScreen);

import {select, call} from 'redux-saga/effects';
import {alfInvoiceStatusTypes} from '@ace-de/eua-entity-types';
import fetchInvoiceReadOnlyApprovalData from './fetchInvoiceReadOnlyApprovalData';

const loadReadOnlyManualCheckInformation = function* loadReadOnlyManualCheckInformation({payload}) {
    const {match} = payload;
    const {invoiceId, serviceCaseId} = match.params;
    const {invoices} = yield select(state => state.invoices);
    const invoice = invoices[invoiceId];

    if (invoice && ![
        alfInvoiceStatusTypes.IN_CREATION,
        alfInvoiceStatusTypes.OPEN,
        alfInvoiceStatusTypes.MANUAL_CHECK,
    ].includes(invoice.status)) {
        yield call(fetchInvoiceReadOnlyApprovalData, {
            payload: {
                invoiceId,
                serviceCaseId,
                status: invoice.status,
            },
        });
    }
};
export default loadReadOnlyManualCheckInformation;

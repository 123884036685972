import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {useRouteUnmountEffect, withRouter} from '@computerrock/formation-router';
import {alfInvoiceChannelTypes, alfInvoiceStatusTypes, alfFailedConstraintTypes, alfServiceTypes} from '@ace-de/eua-entity-types';
import {checkmarkIcon, Icon, InputCurrency, Panel, useStyles, DropDownTrigger, PopOver, DataRow, withDropDownProvider, detailsIcon, Tooltip} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {useTranslate} from '@computerrock/formation-i18n';
import * as invoiceSelectors from '../invoiceSelectors';
import config from '../../config';
import {serviceIcons} from './serviceIcons';
import * as invoiceActionTypes from '../invoiceActionTypes';
import useBeforeUnloadEffect from '../../useBeforeUnloadEffect';
import serviceManager from '../../serviceManager';
import {formatInvoiceServiceLine} from '../formatInvoiceServiceLine';
import {formatDurationTime} from '../formatDurationTime';
import styles from './InvoiceOverviewServicePanel.module.scss';

const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');


// todo populate with proper values for other channels (ecs, mia, ella)
const InvoiceOverviewServicePanel = props => {
    const {cx} = useStyles(props, styles);
    const {createTranslateShorthand, translate, activeLocale} = useTranslate();
    const translatePanel = createTranslateShorthand('invoice_overview_service_panel');
    const {invoice, services, invoiceServices, submitInvoiceServices, handleApprovedAmountChange, location} = props;

    const totalRequestedAmount = invoiceServices.reduce((acc, invoiceService) => {
        return acc + invoiceService?.requestedAmount;
    }, 0);
    const totalCostCoverageAmount = invoiceServices.reduce((acc, invoiceService) => {
        return acc + invoiceService?.assignedAmount;
    }, 0);
    const totalBudgetAmount = invoiceServices.reduce((acc, invoiceService) => {
        return acc + invoiceService?.budgetAmount;
    }, 0);
    const totalApprovedAmount = invoiceServices.reduce((acc, invoiceService) => {
        return acc + Number(invoiceService?.approvedAmount?.replace(',', '.'));
    }, 0);

    const getPrice = ({price, currency}) => {
        if (typeof price === 'number' && price >= 0) {
            return price.toLocaleString(activeLocale, {
                style: 'currency',
                currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
    };

    const handleOnTabChange = useCallback(() => {
        if (!invoice?.id || !invoiceServices || invoice?.status !== alfInvoiceStatusTypes.MANUAL_CHECK) return;

        const invoiceServiceLines = formatInvoiceServiceLine(invoiceServices, invoice.lines);

        if (invoiceServiceLines.length > 0) {
            invoiceServiceLines.forEach(invoiceServiceLine => {
                submitInvoiceServices({
                    invoiceId: invoice.id,
                    invoiceServiceLineData: invoiceServiceLine,
                    lineNo: invoiceServiceLine?.lineNo,
                    location,
                });
            });
        }
    }, [submitInvoiceServices, invoice, invoiceServices, location]);

    useRouteUnmountEffect(() => {
        handleOnTabChange();
    }, [handleOnTabChange]);

    useBeforeUnloadEffect(event => {
        const invoiceServiceLines = formatInvoiceServiceLine(invoiceServices, invoice.lines);
        if (invoiceServiceLines.length > 0 && invoice.status === alfInvoiceStatusTypes.MANUAL_CHECK) {
            return {
                request: leaAssignmentFlowService.updateInvoiceLines,
                requestData: invoiceServiceLines.map(invoiceServiceLine => ({
                    invoiceId: invoice.id,
                    lineNo: invoiceServiceLine?.lineNo,
                    invoiceLinesData: invoiceServiceLine,
                })),
                isArrayOfRequests: true,
            };
        }
    });

    if (!invoice || !services || !invoiceServices) return;

    const {status, failedInaConstraints} = invoice;
    const hasPriceWarning = status === alfInvoiceStatusTypes.MANUAL_CHECK
        && failedInaConstraints?.length > 0
        && failedInaConstraints.includes(alfFailedConstraintTypes.REQUESTED_AMOUNT_OVER_LIMIT);
    const isReadOnly = invoice.status === alfInvoiceStatusTypes.APPROVED
        || invoice.status === alfInvoiceStatusTypes.PAID
        || invoice.status === alfInvoiceStatusTypes.BOOKED
        || invoice.status === alfInvoiceStatusTypes.CLOSED
        || (invoice.status === alfInvoiceStatusTypes.MANUAL_CHECK && invoice.channel === alfInvoiceChannelTypes.ELLA);

    const renderAdditionalInfoContent = invoiceService => {
        if (!invoiceService) return '-';

        if (invoice.channel === alfInvoiceChannelTypes.MIA && !!invoiceService.comment) return invoiceService.comment;

        switch (invoiceService.service) {
            case alfServiceTypes.SPARE_PARTS_SERVICE: {
                return invoiceService?.subtype
                    ? translate(`global.subtype.${invoiceService.subtype.toLowerCase()}`)
                    : '-';
            }
            case alfServiceTypes.VEHICLE_STORAGE: {
                return invoiceService.type
                    ? translate(`global.type.${invoiceService.type.toLowerCase()}`)
                    : '-';
            }
            case alfServiceTypes.RETURN_JOURNEY:
            case alfServiceTypes.SHORT_TRIP: {
                return invoiceService?.transportationType
                    ? translate(`global.transportation_type.${snakeCase(invoiceService.transportationType)}`)
                    : '-';
            }
            case alfServiceTypes.RENTAL_CAR: {
                return invoiceService.additionalServiceRequested
                    ? invoiceService.additionalServiceRequested.map(additionalService => {
                        return translate(`global.additional_service_requested.${snakeCase(additionalService.toLowerCase())}`);
                    }).join(', ')
                    : '-';
            }
            case alfServiceTypes.ACCOMMODATION: {
                return invoiceService?.accommodationType
                    ? translate(`global.accommodation_type.${snakeCase(invoiceService.accommodationType)}`)
                    : '-';
            }
            case alfServiceTypes.EMPTY_TRIP: {
                return invoiceService?.reason
                    ? translate(`global.reason.${snakeCase(invoiceService.reason)}`)
                    : '-';
            }
            case alfServiceTypes.VEHICLE_OPENING:
            case alfServiceTypes.ROADSIDE_ASSISTANCE: {
                return invoiceService?.outcome
                    ? translate(`global.outcome.${snakeCase(invoiceService.outcome)}`)
                    : '-';
            }
            case alfServiceTypes.SICK_PERSON_TRANSPORT: {
                return invoiceService?.medicalTransportOptions?.length > 0
                    ? invoiceService?.medicalTransportOptions.map(medicalTransportOption => {
                        return translate(`global.medical_transport_options.${medicalTransportOption.toLowerCase()}`);
                    }).join(', ')
                    : '-';
            }
            default:
                // no-op
        }

        return '-';
    };

    return (
        <Panel
            title={translatePanel('panel_title.services_overview', {isVATIncluded: invoice?.isVATIncluded
                ? translate('global.invoice_amount_types.gross').toLowerCase()
                : translate('global.invoice_amount_types.net_income').toLowerCase()})}
            className={cx('ace-c-panel--full-bleed-content')}
        >
            <Table qaIdent="invoice-service-panel">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={3} qaIdentPart="invoice-line-service" className={cx('ace-u-padding--left-32')}>
                            {translatePanel('table_header.services')}
                        </TableCell>
                        <TableCell qaIdentPart="invoice-channel">
                            {translatePanel('table_header.assignment')}
                        </TableCell>
                        <TableCell colSpan={2} qaIdentPart="invoice-line-duration">
                            {translatePanel('table_header.duration')}
                        </TableCell>
                        <TableCell qaIdentPart="invoice-line-quantity">
                            {translatePanel('table_header.quantity')}
                        </TableCell>
                        <TableCell
                            colSpan={2}
                            qaIdentPart="invoice-line-requested-amount"
                            className={cx('global!ace-u-typography--align-right')}
                        >
                            {translatePanel('table_header.requested_amount')}
                        </TableCell>
                        <TableCell
                            colSpan={2}
                            qaIdentPart="invoice-line-cost-coverage-amount"
                            className={cx('global!ace-u-typography--align-right')}
                        >
                            {translatePanel('table_header.cost_coverage_amount')}
                        </TableCell>
                        <TableCell
                            colSpan={2}
                            qaIdentPart="invoice-line-budget-amount"
                            className={cx('global!ace-u-typography--align-right')}
                        >
                            {translatePanel('table_header.budget_amount')}
                        </TableCell>
                        <TableCell
                            colSpan={2}
                            qaIdentPart="invoice-line-approved-amount"
                            className={cx('global!ace-u-typography--align-right')}
                        >
                            {translatePanel('table_header.approved_amount')}
                        </TableCell>
                        <TableCell colSpan={6} qaIdentPart="invoice-line-additional-info" className={cx('ace-u-padding--right-32')}>
                            {translatePanel('table_header.additional_info')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invoiceServices.length > 0 && invoiceServices.map(invoiceService => {
                        const additionalInfoText = renderAdditionalInfoContent(invoiceService);

                        return (
                            <TableRow
                                key={invoiceService?.lineNo}
                                qaIdentPart={invoiceService?.lineNo}
                            >
                                <TableCell colSpan={3} qaIdentPart="invoice-line-service" qaIdentPartPostfix={invoiceService?.lineNo} className={cx('ace-u-padding--left-32')}>
                                    <div className={cx('global!ace-u-flex', 'global!ace-u-flex--align-center')}>
                                        {serviceIcons[invoiceService?.service]?.icon && (
                                            <Icon
                                                icon={serviceIcons[invoiceService?.service].icon}
                                                className={cx([
                                                    'global!ace-u-margin--right-8',
                                                    'ace-c-invoice-overview-service-panel__service-icon',
                                                ])}
                                            />
                                        )}
                                        {invoiceService?.service
                                            ? translate(`global.service_type.${invoiceService.service.toLowerCase()}`)
                                            : '-'}
                                    </div>
                                </TableCell>
                                <TableCell qaIdentPart="invoice-channel" qaIdentPartPostfix={invoiceService?.lineNo}>
                                    {invoice.channel === alfInvoiceChannelTypes.ECS
                                        || invoice.channel === alfInvoiceChannelTypes.ELLA
                                        ? <Icon icon={checkmarkIcon} />
                                        : '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="invoice-line-duration" qaIdentPartPostfix={invoiceService?.lineNo}>
                                    <div className={cx('global!ace-u-flex')}>
                                        {invoiceService?.durationMs ? formatDurationTime(invoiceService.durationMs, translate) : '-'}
                                        {!!invoiceService?.durationMs && (
                                            <DropDownTrigger>
                                                <div>
                                                    <Icon
                                                        icon={detailsIcon}
                                                        className={cx('global!ace-u-margin--left-8')}
                                                    />
                                                </div>
                                                <PopOver alignment="end" hasBorder={true}>
                                                    <div
                                                        className={cx([
                                                            'global!ace-u-flex',
                                                            'global!ace-u-flex--direction-column',
                                                            'global!ace-u-grid-column--span-4',
                                                        ])}
                                                    >
                                                        <DataRow label={translatePanel('data_row_label.start_date')}>
                                                            {invoiceService?.serviceStartDateTime
                                                                ? moment(invoiceService.serviceStartDateTime)
                                                                    .format('DD.MM.YYYY - HH:mm')
                                                                : '-'
                                                            }
                                                        </DataRow>
                                                        <DataRow label={translatePanel('data_row_label.end_date')}>
                                                            {invoiceService?.serviceEndDateTime
                                                                ? moment(invoiceService.serviceEndDateTime)
                                                                    .format('DD.MM.YYYY - HH:mm')
                                                                : '-'
                                                        }
                                                        </DataRow>
                                                        <DataRow label={translatePanel('data_row_label.billing_type')}>
                                                            {invoice.billingType
                                                                ? translate(`global.billing_type.${invoice.billingType.toLowerCase()}`)
                                                                : '-'}
                                                        </DataRow>
                                                    </div>
                                                </PopOver>
                                            </DropDownTrigger>
                                        )}
                                    </div>
                                </TableCell>
                                <TableCell qaIdentPart="invoice-line-quantity" qaIdentPartPostfix={invoiceService?.lineNo}>
                                    {invoiceService?.quantity || '-'}
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="invoice-line-requested-amount"
                                    qaIdentPartPostfix={invoiceService?.lineNo}
                                    className={cx('global!ace-u-typography--align-right')}
                                >
                                    {getPrice({
                                        price: invoiceService?.requestedAmount,
                                        currency: config.CURRENCY,
                                    }) || '-'}
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="invoice-line-cost-coverage-amount"
                                    qaIdentPartPostfix={invoiceService?.lineNo}
                                    className={cx('global!ace-u-typography--align-right')}
                                >
                                    {getPrice({
                                        price: Number(invoiceService?.assignedAmount),
                                        currency: config.CURRENCY,
                                    }) || '-'}
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="invoice-line-budget-amount"
                                    qaIdentPartPostfix={invoiceService?.lineNo}
                                    className={cx('global!ace-u-typography--align-right')}
                                >
                                    {getPrice({
                                        price: Number(invoiceService?.budgetAmount),
                                        currency: config.CURRENCY,
                                    }) || '-'}
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="invoice-line-approved-amount"
                                    qaIdentPartPostfix={invoiceService?.lineNo}
                                    className={cx('global!ace-u-typography--align-right')}
                                >
                                    {!isReadOnly
                                        ? (
                                            <InputCurrency
                                                name={`${invoiceService?.lineNo}ApprovedAmountInput`}
                                                value={invoiceService?.approvedAmount}
                                                inputClassName={cx('global!ace-u-typography--align-right')}
                                                onChange={value => {
                                                    handleApprovedAmountChange(value, invoiceService?.lineNo);
                                                }}
                                                onBlur={value => {
                                                    handleApprovedAmountChange(value, invoiceService?.lineNo);
                                                }}
                                                isDisabled={invoice.channel === alfInvoiceChannelTypes.ELLA}
                                            />
                                        )
                                        : getPrice({
                                            price: Number(invoiceService?.approvedAmount),
                                            currency: config.CURRENCY,
                                        }) || '-'}
                                </TableCell>
                                <TableCell
                                    colSpan={6}
                                    qaIdentPart="invoice-line-additional-info"
                                    qaIdentPartPostfix={invoiceService?.lineNo}
                                    className={cx('ace-u-padding--right-32', 'ace-c-table__cell--overflow-visible')}
                                >
                                    {additionalInfoText.length > config.ADDITIONAL_INFO_CHAR_LIMIT ? (
                                        <Tooltip message={additionalInfoText}>
                                            <div className={cx('ace-c-invoice-overview-service-panel__additional-info')}>
                                                {additionalInfoText}
                                            </div>
                                        </Tooltip>
                                    ) : additionalInfoText}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                <TableHead>
                    <TableRow className={cx('ace-c-table__row--highlighted')}>
                        <TableCell colSpan={2} qaIdentPart="invoice-line-total" className={cx('ace-u-padding--left-32')}>
                            {translatePanel('table_header.total').toUpperCase()}
                        </TableCell>
                        <TableCell colSpan={5} />
                        <TableCell
                            colSpan={2}
                            className={cx('global!ace-u-typography--align-right', {
                                'global!ace-u-typography--color-warning': hasPriceWarning,
                            })}
                            qaIdentPart="invoice-line-total-requested-amount"
                        >
                            {getPrice({price: totalRequestedAmount, currency: config.CURRENCY}) || '-'}
                        </TableCell>
                        <TableCell
                            colSpan={2}
                            qaIdentPart="invoice-line-total-cost-coverage-amount"
                            className={cx('global!ace-u-typography--align-right')}
                        >
                            {getPrice({price: totalCostCoverageAmount, currency: config.CURRENCY}) || '-'}
                        </TableCell>
                        <TableCell
                            colSpan={2}
                            qaIdentPart="invoice-line-total-budget-amount"
                            className={cx('global!ace-u-typography--align-right')}
                        >
                            {getPrice({price: totalBudgetAmount, currency: config.CURRENCY}) || '-'}
                        </TableCell>
                        <TableCell
                            colSpan={2}
                            qaIdentPart="invoice-line-total-approved-amount"
                            className={cx('global!ace-u-typography--align-right')}
                        >
                            {getPrice({price: totalApprovedAmount, currency: config.CURRENCY}) || '-'}
                        </TableCell>
                        <TableCell colSpan={6} className={cx('ace-u-padding--right-32')} />
                    </TableRow>
                </TableHead>
            </Table>
        </Panel>
    );
};

InvoiceOverviewServicePanel.propTypes = {
    invoice: PropTypes.object,
    location: PropTypes.object,
    services: PropTypes.array,
    invoiceServices: PropTypes.arrayOf(PropTypes.object),
    submitInvoiceServices: PropTypes.func.isRequired,
    handleApprovedAmountChange: PropTypes.func.isRequired,
};

InvoiceOverviewServicePanel.defaultProps = {
    invoice: null,
    services: [],
    invoiceServices: [],
    location: null,
};

const mapStateToProps = (state, props) => {
    const getInvoice = invoiceSelectors.createInvoicesSelector();
    return {
        invoice: getInvoice(state, props),
        services: invoiceSelectors.getServices(state),
    };
};

const mapDispatchToProps = dispatch => ({
    submitInvoiceServices: payload => dispatch({
        type: invoiceActionTypes.SUBMIT_INVOICE_SERVICE_LINES,
        payload,
    }),
});

export default withDropDownProvider(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceOverviewServicePanel)),
);
